import Vue from 'vue';
import Vuex from 'vuex';
import { UserInfo } from '@/interfaces/userInfo';
import { State } from '@/interfaces/state';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    showGlobalLoader: false,
    userInfo: null,
    underCuration: false
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    },
    setUserInfo: (state, userInfo: UserInfo) => {
      state.userInfo = userInfo;
    },
    setUnderCuration: (state, payload: boolean) => {
      state.underCuration = payload;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo) => {
      commit('setUserInfo', userInfo);
    },
    setUnderCuration: ({ commit }, isUnderCuration: boolean) => {
      commit('setUnderCuration', isUnderCuration);
    }
  },
  modules: {}
});
