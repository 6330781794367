import {
  Feature,
  FeatureCollection,
  MultiPolygon,
  Polygon,
  LineString,
  lineString,
  Point,
  Position
} from '@turf/helpers';
import { featureEach } from '@turf/meta';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import booleanPointOnLine from '@turf/boolean-point-on-line';
import booleanCrosses from '@turf/boolean-crosses';
import lineIntersect from '@turf/line-intersect';
import { getCoords } from '@turf/invariant';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import polygonToLine from '@turf/polygon-to-line';

function isPointOnPolygonSide(position: Position, polygon: Feature<Polygon | MultiPolygon>) {
  if (polygon.geometry.coordinates.length) {
    for (let i = 0; i < polygon.geometry.coordinates[0].length - 1; i++) {
      const line = lineString([
        polygon.geometry.coordinates[0][i] as Position,
        polygon.geometry.coordinates[0][i + 1] as Position
      ]);
      if (booleanPointOnLine(position, line)) {
        return true;
      }
    }
  }
  return false;
}

function getPositionsForRegion(
  originalLine: Feature<LineString>,
  polygon: Feature<Polygon | MultiPolygon>
): Position[] {
  const positionsAtRegion: Position[] = [];
  let prevIncluded = false;
  let prevPosition: Position;
  const polygonLine = polygonToLine(polygon);

  for (let position of getCoords(originalLine)) {
    let included = booleanPointInPolygon(position, polygon) || isPointOnPolygonSide(position, polygon);

    if (!included && !prevPosition) {
      position = nearestPointOnLine(polygonLine as Feature<LineString>, position).geometry.coordinates;
      included = true;
    }

    const hasOneIntersection = prevIncluded !== included && prevPosition;
    const canHaveTwoIntersection = !included && !prevIncluded && prevPosition;

    if (hasOneIntersection || canHaveTwoIntersection) {
      const fragment = lineString([prevPosition, position]);
      const intersect = lineIntersect(fragment, polygon);

      if (intersect.features.length) {
        positionsAtRegion.push(...intersect.features.map((f: Feature<Point>) => getCoords(f))); // tslint:disable-line
      } else {
        included = true;
      }
    }

    if (included) {
      positionsAtRegion.push(position);
    }

    prevIncluded = included;
    prevPosition = position;
  }

  return positionsAtRegion;
}

export function lineClipByPolygon(
  lines: FeatureCollection<LineString>,
  polygon: Feature<Polygon>
): Array<Feature<LineString>> {
  const resultLines = [];
  featureEach(lines, (originalLine: Feature<LineString>) => {
    const crosses = booleanCrosses(originalLine, polygon);

    if (!crosses) {
      const points = getCoords(originalLine);
      const firstPoint = points[0];
      const firstIncluded = booleanPointInPolygon(firstPoint, polygon) || isPointOnPolygonSide(firstPoint, polygon);
      const lastPoint = points[points.length - 1];
      const lastIncluded = booleanPointInPolygon(lastPoint, polygon) || isPointOnPolygonSide(lastPoint, polygon);
      if (firstIncluded || lastIncluded) {
        resultLines.push(originalLine);
      }
      return;
    }

    const positionsAtRegion = getPositionsForRegion(originalLine, polygon);

    const line = lineString(positionsAtRegion, originalLine.properties);
    resultLines.push(line);
  });

  return resultLines;
}
