import { message } from 'ant-design-vue';
import Axios from 'axios';
import { AxiosError } from 'axios';

import { AnalyticData } from '@/interfaces/analyticData';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import { AnalyticRegionCountByState } from '@/interfaces/analyticRegionCountByState';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { Parcel } from '@/interfaces/parcel';
import { Organization } from '@/interfaces/organization';
import { Unit } from '@/interfaces/unit';

class API {
  static _URL = `${process.env.VUE_APP_CURATION_SERVICE}/api`;
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}/api`;

  static getDroneSurvey(surveyId: string): Promise<DroneSurvey> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/survey?surveyId=${surveyId}&source=drone`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getSurveyAnalytic(surveyId: string): Promise<AnalyticData> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/analytic-status?id=${surveyId}&analytic=sowing&type=drone`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load analytic status', error));
  }

  static getParcel(parcelId: string): Promise<Parcel> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcel?id=${parcelId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load parcel', error));
  }

  static getOrganization(orgId: string): Promise<Organization> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/organization?id=${orgId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load organization', error));
  }

  static getUnit(unitId: string): Promise<Unit> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit?id=${unitId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load unit', error));
  }

  static getAnalyticRegion(id: string): Promise<AnalyticRegion> {
    return Axios.get(`${API._URL}/analytic_regions/${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load analytic region', error));
  }

  static getAnalyticRegionsAround(id: string): Promise<AnalyticRegion[]> {
    return Axios.get(`${API._URL}/analytic_regions/around/${id}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load regions around region to curate', error));
  }

  static getNextAnalyticRegion(): Promise<AnalyticRegion> {
    return Axios.post(`${API._URL}/analytic_regions/next-for-curation`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load next region', error));
  }

  static getAnalyticRegionCurationRequiredCount(): Promise<AnalyticRegionCountByState> {
    return Axios.get(`${API._URL}/analytic_regions/count?state=CurationRequired`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not load amount of analytic regions which needed curation', error));
  }

  static updateRegion(id: string, payload: Partial<AnalyticRegion>): Promise<void> {
    return Axios.patch(`${API._URL}/analytic_regions/${id}`, payload, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => API.handleError('Could not update the region', error));
  }

  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize?role=${role}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static handleError(clientError = 'something went wrong', serviceError: AxiosError): void {
    let serviceErrorMsg = '';
    if (serviceError.response) {
      serviceErrorMsg = `${serviceError.message}, statusCode: ${serviceError.response.status}`;
    } else {
      serviceErrorMsg = serviceError.message;
    }

    message.error((createElement) => {
      return createElement('span', [
        createElement('span', clientError),
        createElement('br'),
        createElement('span', serviceErrorMsg)
      ]);
    }, 5);

    throw serviceError;
  }
}

export default API;
