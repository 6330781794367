
import Mapbox from 'mapbox-gl-vue';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MapComponent } from '@/interfaces/mapComponent';
import { LineType } from '@/enums/line-type';
import { Feature, FeatureCollection, featureCollection, LineString, Polygon, point, Point } from '@turf/helpers';
import { PaintMode } from '@/components/Map/paint-mode';
import { drawStyles } from '@/components/Map/draw-styles';
import {
  getFeaturesUnderPolygon,
  splitLinesToInSideAndOutSideOfPolygon,
  getInsidePolygonByOffset
} from '@/shared/helpers';
import center from '@turf/center';
import bezier from '@turf/bezier-spline';
import simplify from '@turf/simplify';
import distance from '@turf/distance';
import length from '@turf/length';
import booleanContains, { getMidpoint } from '@turf/boolean-contains';
import cleanCoords from '@turf/clean-coords';
import buffer from '@turf/buffer';
import { Id, Geometry } from '@turf/helpers';
import API from '@/services/api';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import { AnyLayer, GeoJSONSource, LngLat, Map as MapboxMap } from 'mapbox-gl';
import { featureEach } from '@turf/meta';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { Parcel } from '@/interfaces/parcel';
import { message } from 'ant-design-vue';
import { ConvertMode } from '@/enums/convert-mode';
import { AnalyticData } from '@/interfaces/analyticData';
import polygonClipping from 'polygon-clipping';
import { getParallelismStylingRules } from '@/services/constants';
@Component({
  components: {
    Mapbox
  }
})
export default class Map extends Vue implements MapComponent {
  @Prop() showParallelLines: boolean;
  @Prop() showAllLines: boolean;
  private map: MapboxMap;
  private draw: MapboxDraw;
  private readonly segmentColorMap = {
    gap: '#fc0303',
    line: '#03fc7b',
    overlap: '#ffff00',
    default: '#000'
  };
  private readonly parcelPaint = { 'line-width': 3, 'line-color': 'darkgray' };
  private readonly polygonPaint = { 'line-width': 1, 'line-color': '#fff' };
  private drawSelectedFeatureIds: string[] = null;
  private readonly rasterSourceId = 'rasterSource';
  private readonly rasterLayerId = 'rasterLayer';
  private readonly parcelSourceId = 'parcelSource';
  private readonly parcelLayerId = 'parcelLayer';
  private readonly circlesSourceId = 'circlesSource';
  private readonly circlesLayerId = 'circlesLayer';
  private readonly regionSourceId = 'regionSource';
  private readonly regionLayerId = 'regionLayer';
  private readonly regionsAroundSourceId = 'regionsAroundSource';
  private readonly regionsAroundLayerId = 'regionsAroundLayer';
  private readonly regionViewSourceId = 'regionViewSource';
  private readonly regionViewLayerId = 'regionViewLayer';
  private readonly parallelLinesLayerId = 'parallelLinesLayer';
  private readonly parallelLinesSourceId = 'parallelLinesSource';
  private shape: FeatureCollection = null;
  private readonly SPLINE_BUFFER = 0.1;
  private readonly JOIN_LINE_THRESHOLD = 0.5;
  private CLEAN_LINE_THRESHOLD = 0.5;
  private analyticRegion: AnalyticRegion = null;
  private survey: DroneSurvey;
  private analyticData: AnalyticData;
  private parcel: Parcel;
  selectedConvertMode: ConvertMode = null;
  isSplineMode = false;
  drawMode = null;
  drawHistory: Array<FeatureCollection<LineString>> = [];
  convertMode = ConvertMode;
  extendLineThreshold = 10;

  get isEditable(): boolean {
    return this.$store.state.underCuration;
  }

  @Watch('isEditable')
  onEditableChanged(): void {
    if (this.isEditable) {
      this.drawLoadedAnalytic();
    }
  }

  @Watch('showAllLines')
  onShowAllLinesChanged(): void {
    if (this.isEditable) {
      if (this.map.getLayer(this.circlesLayerId)) {
        this.map.setLayoutProperty(this.circlesLayerId, 'visibility', this.showAllLines ? 'visible' : 'none');
      }
      this.map.getStyle().layers.forEach((layer: AnyLayer) => {
        if (layer.id.startsWith('gl-draw')) {
          this.map.setLayoutProperty(layer.id, 'visibility', this.showAllLines ? 'visible' : 'none');
        }
      });
    }
    if (this.map.getLayer(this.regionViewLayerId)) {
      this.map.setLayoutProperty(this.regionViewLayerId, 'visibility', this.showAllLines ? 'visible' : 'none');
    }
  }

  @Watch('showParallelLines')
  onShowParallelLinesChange(): void {
    if (this.showParallelLines) {
      this.drawParallelLines();
    } else {
      if (this.map.getLayer(this.parallelLinesLayerId)) {
        this.map.removeLayer(this.parallelLinesLayerId);
        this.map.removeSource(this.parallelLinesSourceId);
      }
    }
  }

  private drawParallelLines(): void {
    this.map.addSource(this.parallelLinesSourceId, {
      type: 'vector',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${this.survey.id}/parallelism/{z}/{x}/{y}.mvt?v=${this.survey.LastUpdate}`
      ],
      minzoom: 10,
      maxzoom: 22
    });
    this.map.addLayer({
      type: 'fill',
      id: this.parallelLinesLayerId,
      source: this.parallelLinesSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        'fill-color': ['case', ...getParallelismStylingRules(), '#000']
      }
    });
  }

  mounted() {
    this.loadRegion();
  }

  destroyed() {
    document.onkeyup = null;
  }

  onMapLoaded(map) {
    this.map = map;
    this.createRegionLayer();
    this.createParcelLayer();
    this.createViewRegionLayer();
    this.createRegionsAroundLayer();

    if (this.analyticRegion) {
      this.drawLoadedAnalytic();
    }

    document.onkeyup = (e) => {
      if (e.shiftKey) {
        if (this.isEditable) {
          switch (e.code) {
            case 'KeyA':
              this.convertTo(ConvertMode.gap);
              break;
            case 'KeyS':
              this.convertTo(ConvertMode.line);
              break;
            case 'KeyD':
              this.convertTo(ConvertMode.delete);
              break;
            case 'KeyQ':
              this.toSplineMode();
              break;
            case 'KeyZ':
              this.applyFromHistory();
              break;
            case 'KeyX':
              this.copy();
              break;
            case 'KeyF':
              this.snapGaps();
              break;
          }
        }
        if (e.code === 'KeyW') {
          this.$emit('changeShowLines');
        }
      }
    };
  }

  get currentMapMode(): string {
    if (this.isSplineMode) {
      return 'Spline mode';
    }
    if (this.selectedConvertMode) {
      if (this.selectedConvertMode === ConvertMode.gap) {
        return 'Paint gaps mode';
      }
      if (this.selectedConvertMode === ConvertMode.line) {
        return 'Delete gaps mode';
      }
      if (this.selectedConvertMode === ConvertMode.delete) {
        return 'Delete all lines mode';
      }
    }
    if (this.drawMode === 'draw_line_string') {
      return 'Draw gap mode';
    }
    return null;
  }

  private drawGaps(features: Array<Feature<LineString>>, polygon: Feature<Polygon>): void {
    const lineFeatures = features.filter((feature) => feature.properties.type === LineType.line);
    const gapFeatures = features.filter((feature) => feature.properties.type === LineType.gap);
    const lines = splitLinesToInSideAndOutSideOfPolygon(lineFeatures, polygon);
    if (lines.inside.length > 0) {
      lines.inside.forEach((feature) => {
        feature.properties.type = LineType.gap;
        feature.properties.color = this.getSegmentColor(LineType.gap);
      });
      this.draw.add(featureCollection(lines.inside));
      if (gapFeatures.length > 0) {
        const gaps = splitLinesToInSideAndOutSideOfPolygon(gapFeatures, polygon);
        this.draw.delete(gapFeatures.map((feature) => feature.id));
        this.draw.add(featureCollection(gaps.outside));
      }
      this.saveToHistory();
    }
  }

  private deleteGaps(features: Array<Feature<LineString>>, polygon: Feature<Polygon>): void {
    const gapFeatures = features.filter((feature) => feature.properties.type === LineType.gap);
    const gaps = splitLinesToInSideAndOutSideOfPolygon(gapFeatures, polygon);
    if (gaps.inside.length) {
      this.draw.delete(gapFeatures.map((feature) => feature.id));
      this.draw.add(featureCollection(gaps.outside));
      this.saveToHistory();
    }
  }

  private deleteAll(features: Array<Feature<LineString>>, polygon: Feature<Polygon>): void {
    const lines = splitLinesToInSideAndOutSideOfPolygon(features, polygon);
    if (lines.inside.length) {
      this.draw.delete(features.map((feature) => feature.id));
      this.draw.add(featureCollection(lines.outside));
      this.sortAndUpdate(this.draw.getAll().features).then(() => {
        this.saveToHistory();
      });
    }
  }

  private createDrawLayer(): void {
    const paintMode = new PaintMode();
    paintMode.onDrawFinished = (polygon: Feature<Polygon>) => {
      if (polygon && this.selectedConvertMode) {
        const features = getFeaturesUnderPolygon(this.draw.getAll(), polygon);
        if (this.selectedConvertMode === ConvertMode.gap) {
          this.drawGaps(features, polygon);
        }
        if (this.selectedConvertMode === ConvertMode.line) {
          this.deleteGaps(features, polygon);
        }
        if (this.selectedConvertMode === ConvertMode.delete) {
          this.deleteAll(features, polygon);
        }
      }
    };

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        ['line_string']: true,
        trash: true
      },
      userProperties: true,
      styles: drawStyles,
      modes: {
        paint: paintMode,
        ...MapboxDraw.modes
      }
    });
    this.map.addControl(this.draw);
    this.drawMode = this.draw.getMode();
  }

  private createParcelLayer(): void {
    this.map.addSource(this.parcelSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer(
      {
        type: 'line',
        id: this.parcelLayerId,
        source: this.parcelSourceId,
        paint: this.parcelPaint
      },
      this.regionLayerId
    );
  }

  private createRegionLayer(): void {
    this.map.addSource(this.regionSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.regionLayerId,
      source: this.regionSourceId,
      paint: this.polygonPaint
    });
  }

  private createRegionsAroundLayer(): void {
    this.map.addSource(this.regionsAroundSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.regionsAroundLayerId,
      source: this.regionsAroundSourceId,
      paint: {
        ['line-width']: 1,
        ['line-opacity']: 0.8,
        ['line-color']: [
          'case',
          ['==', ['get', 'type'], 'line'],
          this.getSegmentColor('line'),
          ['==', ['get', 'type'], 'gap'],
          this.getSegmentColor('gap'),
          this.segmentColorMap.default
        ]
      }
    });
  }

  private createViewRegionLayer(): void {
    this.map.addSource(this.regionViewSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.regionViewLayerId,
      source: this.regionViewSourceId,
      paint: {
        ['line-width']: 1,
        ['line-opacity']: 0.8,
        ['line-color']: [
          'case',
          ['==', ['get', 'type'], 'line'],
          this.getSegmentColor('line'),
          ['==', ['get', 'type'], 'gap'],
          this.getSegmentColor('gap'),
          this.segmentColorMap.default
        ]
      }
    });
  }

  private changeTypeForFeatures(type: LineType, features: Array<Feature<LineString>>): void {
    this.changeTypeForFeatureIds(
      type,
      features.map((feature) => feature.id)
    );
  }

  private changeTypeForFeatureIds(type: LineType, featureIds: Id[]): void {
    const color = this.getSegmentColor(type);
    featureIds.forEach((id: Id) => {
      this.draw.setFeatureProperty(id, 'type', type);
      this.draw.setFeatureProperty(id, 'color', color);
    });
  }

  private getSegmentColor(type: string): string {
    return this.segmentColorMap[type] || this.segmentColorMap.default;
  }

  private saveToHistory(): void {
    if (this.draw) {
      const lines = this.draw.getAll();
      if (this.drawHistory.length === 5) {
        this.drawHistory = this.drawHistory.slice(1);
      }
      this.drawHistory.push(lines);
    }
  }

  applyFromHistory(): void {
    if (this.drawHistory.length > 1) {
      const last = this.drawHistory.pop();
      this.drawLines(this.drawHistory[this.drawHistory.length - 1]);
    }
  }

  copy(): void {
    if (this.drawSelectedFeatureIds && this.drawSelectedFeatureIds.length) {
      const copyIds = [...this.drawSelectedFeatureIds];
      const lines = this.drawSelectedFeatureIds.map((id: string) => {
        const line = this.draw.get(id);
        delete line.id;
        return line;
      });
      const all = this.draw.getAll();
      this.sortAndUpdate([...all.features, ...lines]).then(() => {
        this.saveToHistory();
        this.draw.changeMode('simple_select', {
          featureIds: copyIds
        });
      });
    }
  }

  convertTo(mode: ConvertMode): void {
    this.isSplineMode = false;
    this.drawMode = null;
    if (this.selectedConvertMode === mode) {
      this.selectedConvertMode = null;
      this.draw.changeMode('simple_select');
      return;
    }
    this.drawSelectedFeatureIds = null;
    this.selectedConvertMode = mode;
    this.draw.changeMode('paint', { color: this.getSegmentColor(mode) });
  }

  private simplifyLine(line: Feature<LineString>): Feature<LineString> {
    const cv = cleanCoords(line);
    return simplify(cv, { tolerance: 0.000001 });
  }

  drawSpline(line: Feature<LineString>): void {
    this.draw.delete([line.id]);
    const curved = this.simplifyLine(bezier(line));
    const filterPolygon = buffer(curved, this.SPLINE_BUFFER, { units: 'meters' });
    const features = getFeaturesUnderPolygon(this.draw.getAll(), filterPolygon);
    this.draw.delete(features.map((feature) => feature.id));
    const ids = this.draw.add(featureCollection([curved]));
    this.changeTypeForFeatureIds(LineType.line, ids);
  }

  toSplineMode(): void {
    this.selectedConvertMode = null;
    this.drawMode = null;
    this.isSplineMode = !this.isSplineMode;
    if (this.isSplineMode) {
      this.draw.changeMode('draw_line_string', { color: this.segmentColorMap.line });
    } else {
      this.draw.changeMode('simple_select');
    }
  }

  connectCloseLines(): void {
    const allLines = this.getAllLines();
    allLines.features.forEach((feat) => {
      feat.geometry.coordinates = this.removeDuplicates(feat.geometry.coordinates);
    });
    const beforeJoin = allLines.features.length;
    for (let i = allLines.features.length - 1; i >= 1; i--) {
      const leftLine = allLines.features[i];
      if (leftLine.properties.type === LineType.gap) continue;
      for (let j = i - 1; j >= 0; j--) {
        const rightLine = allLines.features[j];
        if (rightLine.properties.type === LineType.gap) continue;

        const rightStart = rightLine.geometry.coordinates[0];
        const rightEnd = rightLine.geometry.coordinates[rightLine.geometry.coordinates.length - 1];

        let leftStart = leftLine.geometry.coordinates[0];
        let leftEnd = leftLine.geometry.coordinates[leftLine.geometry.coordinates.length - 1];

        let dist1 = distance(rightStart, leftEnd, { units: 'meters' });
        let dist2 = distance(rightEnd, leftStart, { units: 'meters' });
        if (dist1 < this.JOIN_LINE_THRESHOLD || dist2 < this.JOIN_LINE_THRESHOLD) {
          leftLine.properties.color = 'white';
          let midPoint = getMidpoint(leftEnd, rightStart);

          if (dist2 < this.JOIN_LINE_THRESHOLD) {
            midPoint = getMidpoint(leftStart, rightEnd);
            leftLine.geometry.coordinates = [
              ...rightLine.geometry.coordinates.splice(0, rightLine.geometry.coordinates.length - 1),
              midPoint,
              ...leftLine.geometry.coordinates.splice(1, leftLine.geometry.coordinates.length - 1)
            ];
          } else {
            leftLine.geometry.coordinates = [
              ...leftLine.geometry.coordinates.splice(0, leftLine.geometry.coordinates.length - 1),
              midPoint,
              ...rightLine.geometry.coordinates.splice(1, rightLine.geometry.coordinates.length - 1)
            ];
          }

          allLines.features.splice(j, 1);
          break;
        } else {
          dist1 = distance(rightStart, leftStart, { units: 'meters' });
          dist2 = distance(rightEnd, leftEnd, { units: 'meters' });
          if (dist1 < this.JOIN_LINE_THRESHOLD || dist2 < this.JOIN_LINE_THRESHOLD) {
            leftLine.properties.color = 'white';
            let midPoint = getMidpoint(leftStart, rightStart);
            if (dist2 < this.JOIN_LINE_THRESHOLD) {
              midPoint = getMidpoint(leftEnd, rightEnd);
              leftLine.geometry.coordinates = [
                ...leftLine.geometry.coordinates.splice(0, leftLine.geometry.coordinates.length - 1),
                midPoint,
                ...rightLine.geometry.coordinates.reverse().splice(1, rightLine.geometry.coordinates.length - 1)
              ];
            } else {
              leftLine.geometry.coordinates = [
                ...rightLine.geometry.coordinates.reverse().splice(0, rightLine.geometry.coordinates.length - 1),
                midPoint,
                ...leftLine.geometry.coordinates.splice(1, leftLine.geometry.coordinates.length - 1)
              ];
            }
            allLines.features.splice(j, 1);
            break;
          }
        }
      }
    }
    if (allLines.features.length !== beforeJoin) {
      this.drawLines(allLines);
      this.saveToHistory();
      message.success(`Connected ${beforeJoin - allLines.features.length} line(s)! Highlighted in white.`, 5);
    } else {
      message.info('No close lines to connect', 5);
    }
  }

  cleanSmallGaps(): void {
    if (this.analyticRegion) {
      const squarePolygon = getInsidePolygonByOffset(this.analyticRegion.polygon, this.CLEAN_LINE_THRESHOLD);
      const allGaps = this.getAllLines().features.filter((x) => x.properties.type === LineType.gap);
      const linesInPolygon = allGaps.filter((feature) => {
        return booleanContains(squarePolygon, feature);
      });
      if (linesInPolygon.length) {
        const newLines: Feature<LineString>[] = [];
        linesInPolygon.forEach((line: Feature<LineString>) => {
          const lineLength = length(line, { units: 'meters' });
          if (lineLength >= this.CLEAN_LINE_THRESHOLD) {
            newLines.push(line);
          }
        });
        if (linesInPolygon.length !== newLines.length) {
          this.draw.delete(linesInPolygon.map((feature) => feature.id));
          this.draw.add(featureCollection(newLines));
          this.saveToHistory();
          message.success(`Cleaned ${linesInPolygon.length - newLines.length} gaps!`, 5);
        } else {
          message.info('No small gaps to clean', 5);
        }
      }
    }
  }

  showStartEndPoints(): void {
    if (this.map.getLayer(this.circlesLayerId)) {
      this.cleanupLayer(this.circlesLayerId, this.circlesSourceId);
      return;
    }

    const allLines = this.getAllLines();
    if (allLines.features.length) {
      const points: Feature<Point>[] = [];
      allLines.features.forEach((line: Feature<LineString>) => {
        if (line.properties.type === LineType.line) {
          points.push(point(line.geometry.coordinates[0]));
          points.push(point(line.geometry.coordinates[line.geometry.coordinates.length - 1]));
        }
      });

      if (points.length) {
        this.map.addSource(this.circlesSourceId, {
          type: 'geojson',
          data: featureCollection(points)
        });

        this.map.addLayer({
          id: this.circlesLayerId,
          type: 'circle',
          source: this.circlesSourceId,
          paint: {
            'circle-radius': 5,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ca2c92',
            'circle-opacity': 0
          }
        });
        return;
      }
    }
    message.info('No lines', 5);
  }

  stopDrawing(): void {
    this.draw.changeMode('simple_select');
    this.isSplineMode = false;
    this.drawMode = null;
    this.selectedConvertMode = null;
    this.drawSelectedFeatureIds = null;
  }

  getAllLines(): FeatureCollection<LineString> {
    return this.draw.getAll();
  }

  getParcel(): FeatureCollection {
    return this.shape;
  }

  private drawLines(geoJson: FeatureCollection): void {
    this.draw.deleteAll();
    this.draw.add(geoJson);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawSelectionChange(e: any): void {
    const newSelectedFeaturesIds = e && e.features && e.features.length ? e.features.map((f) => f.id) : [];
    this.reSortFeatures(newSelectedFeaturesIds).then(() => {
      this.drawSelectedFeatureIds = newSelectedFeaturesIds.length ? newSelectedFeaturesIds : null;
    });
  }

  private sortFeatures(features: Feature<LineString>[]): Feature[] {
    features = features.filter((feature) => feature.geometry.coordinates.length > 1);
    const lines = features.filter((feature) => feature.properties.type === LineType.line);
    const gaps = features.filter((feature) => feature.properties.type === LineType.gap);
    return [...lines, ...gaps];
  }

  private sortAndUpdate(features: Feature<LineString>[]): Promise<void> {
    return new Promise<void>((resolve) => {
      const sorted = this.sortFeatures(features);
      window.setTimeout(() => {
        this.draw.deleteAll();
        this.draw.add(featureCollection(sorted));
        this.drawSelectedFeatureIds = null;
        resolve();
      }, 10);
    });
  }

  private reSortFeatures(newSelectedFeaturesIds: string[]): Promise<void> {
    if (this.drawSelectedFeatureIds && this.drawSelectedFeatureIds.length > newSelectedFeaturesIds.length) {
      const unSelectedIds = this.drawSelectedFeatureIds.filter((id: string) => !newSelectedFeaturesIds.includes(id));
      const isUnselectLine = unSelectedIds.some((id: string) => {
        const unSelectedLine = this.draw.get(id);
        return unSelectedLine && unSelectedLine.properties.type === LineType.line;
      });
      if (isUnselectLine) {
        const all = this.draw.getAll();
        return this.sortAndUpdate(all.features);
      }
    }
    return Promise.resolve();
  }

  private drawModeChange(): void {
    this.selectedConvertMode = null;
    this.isSplineMode = false;
    this.drawMode = this.draw.getMode();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawCreate(e: any): void {
    if (e.features && e.features.length) {
      if (this.isSplineMode) {
        this.drawSpline(e.features[0]);
        this.isSplineMode = false;
      } else {
        this.changeTypeForFeatures(LineType.gap, e.features);
      }
      this.saveToHistory();
    }
  }

  private drawDelete(): void {
    if (this.draw.getMode() !== 'paint') {
      this.saveToHistory();
      this.drawSelectedFeatureIds = null;
    }
  }

  private drawEditableAnalyticRegion(): void {
    if (!this.analyticRegion) {
      return;
    }

    featureEach(this.analyticRegion.corrected, (feature: Feature) => {
      feature.properties.color = this.getSegmentColor(feature.properties.type);
    });
    const sorted = this.sortFeatures(this.analyticRegion.corrected.features);
    this.drawLines(featureCollection(sorted));
  }

  private drawRegionPolygon(polygon: Feature<Polygon>): void {
    this.updateGeoJsonSource(this.regionSourceId, polygon);
  }

  private drawRasterTiles(): void {
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);
    if (!this.analyticRegion) {
      return;
    }

    this.map.addSource(this.rasterSourceId, {
      type: 'raster',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_DRONE_TILES_FOLDER}/${
          this.analyticRegion.surveyId
        }/{z}/{x}/{y}.png?v=${this.analyticData ? this.analyticData.LastUpdate : '' + new Date().getTime()}`
      ],
      bounds: [this.parcel.LLLong, this.parcel.LLLat, this.parcel.URLong, this.parcel.URLat],
      tileSize: 256
    });
    this.map.addLayer(
      {
        type: 'raster',
        id: this.rasterLayerId,
        source: this.rasterSourceId
      },
      this.parcelLayerId
    );
  }

  private drawParcel(): void {
    if (this.shape) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.updateGeoJsonSource(this.parcelSourceId, this.shape as any);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateGeoJsonSource(sourceId: string, geoJsonData: any): void {
    const source = this.map.getSource(sourceId) as GeoJSONSource; // set data available only for geoJSON;
    if (source) {
      source.setData(geoJsonData);
    }
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  private clearMap(): void {
    if (this.draw) {
      this.draw.deleteAll();
    }

    this.updateGeoJsonSource(this.parcelSourceId, featureCollection([]));
    this.updateGeoJsonSource(this.regionSourceId, featureCollection([]));
    this.updateGeoJsonSource(this.regionsAroundSourceId, featureCollection([]));
    this.updateGeoJsonSource(this.regionViewSourceId, featureCollection([]));
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);
  }

  private loadRegion(): void {
    const regionId = this.$route.query.id;
    if (regionId) {
      this.loadRegionById(regionId as string);
    } else {
      this.loadNextRegion();
    }
  }

  private async drawLoadedAnalytic() {
    if (!this.map) {
      return;
    }
    this.$store.dispatch('showGlobalLoader', false);

    this.cleanupLayer(this.circlesLayerId, this.circlesSourceId);
    if (!this.analyticRegion?.surveyId) {
      this.clearMap();
      return;
    }
    const { surveyId, polygon } = this.analyticRegion;
    const position = center(polygon);
    this.map.setCenter(new LngLat(position.geometry.coordinates[0], position.geometry.coordinates[1]));

    this.drawRegionPolygon(polygon);

    if (this.isEditable) {
      this.enableEditingMode();
      this.drawHistory = [];
      this.saveToHistory();
    } else {
      this.enableViewMode();
    }

    this.analyticData = await API.getSurveyAnalytic(surveyId);

    const unit = await API.getUnit(this.analyticData.UnitID);
    const org = await API.getOrganization(unit.OrganizationID);

    if (org.GapIntervals) {
      this.CLEAN_LINE_THRESHOLD = org.GapIntervals[0][0];
    }

    const loadMainData = API.getDroneSurvey(surveyId)
      .then((survey: DroneSurvey) => {
        this.survey = survey;
        return survey
          ? API.getParcel(survey.ParcelID).then((parcel: Parcel) => {
              this.parcel = parcel;
              this.shape = parcel.Shape;
              this.drawRasterTiles();
              this.drawParcel();
            })
          : Promise.reject();
      })

      .catch((err) => API.handleError('Could not load loadMainData', err));

    Promise.all([loadMainData, this.loadRegionsAround()]);
  }

  private onNewAnalyticLoaded(analyticRegion: AnalyticRegion): void {
    this.analyticRegion = analyticRegion;

    this.$emit('newRegionLoaded', analyticRegion);

    const nextId = analyticRegion ? analyticRegion.id : null;
    if (this.$route.query.id !== nextId) {
      this.$router.push({ path: '/', query: { id: nextId } });
    }

    this.drawLoadedAnalytic();
  }

  private loadRegionsAround(): Promise<void> {
    return API.getAnalyticRegionsAround(this.analyticRegion.id).then((regions: AnalyticRegion[]) => {
      this.updateGeoJsonSource(
        this.regionsAroundSourceId,
        featureCollection(regions.map((region: AnalyticRegion) => region.corrected.features).flat())
      );
    });
  }

  private loadRegionById(id: string): void {
    this.$store.dispatch('showGlobalLoader', true);
    API.getAnalyticRegion(id).then((analyticRegion: AnalyticRegion) => {
      this.onNewAnalyticLoaded(analyticRegion);
    });
  }

  loadNextRegion(): void {
    this.$store.dispatch('showGlobalLoader', true);
    API.getNextAnalyticRegion()
      .then((analyticRegion: AnalyticRegion) => {
        this.onNewAnalyticLoaded(analyticRegion);
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  private enableViewMode(): void {
    if (this.draw) {
      this.map.removeControl(this.draw);
      this.map.off('draw.create', () => false);
      this.map.off('draw.delete', () => false);
      this.map.off('draw.update', () => false);
      this.map.off('draw.selectionchange', () => false);
      this.map.off('draw.modechange', () => false);
      this.draw = null;
    }
    if (this.analyticRegion) {
      this.updateGeoJsonSource(this.regionViewSourceId, this.analyticRegion.corrected);
    }
  }

  private enableEditingMode(): void {
    if (!this.draw) {
      this.createDrawLayer();
    }

    this.map.on('draw.selectionchange', this.drawSelectionChange.bind(this));
    this.map.on('draw.create', this.drawCreate.bind(this));
    this.map.on('draw.delete', this.drawDelete.bind(this));
    this.map.on('draw.update', this.saveToHistory.bind(this));
    this.map.on('draw.modechange', this.drawModeChange.bind(this));
    if (this.analyticRegion) {
      this.updateGeoJsonSource(this.regionViewSourceId, featureCollection([]));
      this.drawEditableAnalyticRegion();
    }
  }

  private nearestPointOnLine(pt: number[], line: number[][]): number[] {
    const xDelta = line[1][0] - line[0][0];
    const yDelta = line[1][1] - line[0][1];

    let closestPoint = [];
    if (xDelta === 0 && yDelta === 0) {
      closestPoint = line[0];
    } else {
      const u = ((pt[0] - line[0][0]) * xDelta + (pt[1] - line[0][1]) * yDelta) / (xDelta * xDelta + yDelta * yDelta);
      closestPoint[0] = line[0][0] + u * xDelta;
      closestPoint[1] = line[0][1] + u * yDelta;
    }
    return closestPoint;
  }

  private fixZaggedEnds(line: number[][]): number[][] {
    if (line.length < 3) {
      return line;
    }
    let slope1 = this.slope(line[0][0], line[0][1], line[1][0], line[1][1]);
    let slope2 = this.slope(line[1][0], line[1][1], line[2][0], line[2][1]);
    if (distance(line[0], line[1]) * 1000 < 2) {
      if ((Math.atan(Math.abs(slope2 - slope1)) * 180) / Math.PI > 5) {
        line[0] = this.nearestPointOnLine(line[0], [line[2], line[1]]);
      }
    }
    if (line.length < 3) {
      return line;
    }
    let last = line.length - 1;
    slope1 = this.slope(line[last][0], line[last][1], line[last - 1][0], line[last - 1][1]);
    slope2 = this.slope(line[last - 1][0], line[last - 1][1], line[last - 2][0], line[last - 2][1]);
    if (distance(line[last], line[last - 1]) * 1000 < 2) {
      if ((Math.atan(Math.abs(slope2 - slope1)) * 180) / Math.PI > 5) {
        line[last] = this.nearestPointOnLine(line[last], [line[last - 2], line[last - 1]]);
      }
    }
    return line;
  }

  private slope(x1: number, y1: number, x2: number, y2: number): number {
    if (x1 === x2) return Math.PI / 2;
    return (y1 - y2) / (x1 - x2);
  }

  private calcIsInsideLineSegment(line1: number[], line2: number[], pnt: number[]): boolean {
    const L2 = (line2[0] - line1[0]) * (line2[0] - line1[0]) + (line2[1] - line1[1]) * (line2[1] - line1[1]);
    if (L2 === 0) return false;
    const r = ((pnt[0] - line1[0]) * (line2[0] - line1[0]) + (pnt[1] - line1[1]) * (line2[1] - line1[1])) / L2;

    return 0 <= r && r <= 1;
  }
  private distance(x: number, y: number, x1: number, y1: number, x2: number, y2: number): number {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq != 0)
      //in case of 0 length line
      param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }
  private getIntersection(
    line1StartX: number,
    line1StartY: number,
    line1EndX: number,
    line1EndY: number,
    line2StartX: number,
    line2StartY: number,
    line2EndX: number,
    line2EndY: number
  ): number[] {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    let result = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false
    };
    const denominator =
      (line2EndY - line2StartY) * (line1EndX - line1StartX) - (line2EndX - line2StartX) * (line1EndY - line1StartY);
    if (denominator === 0) {
      return [line1StartX, line1StartY];
    }
    let a = line1StartY - line2StartY;
    let b = line1StartX - line2StartX;
    const numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
    const numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1StartX + a * (line1EndX - line1StartX);
    result.y = line1StartY + a * (line1EndY - line1StartY);

    if (a > 0 && a < 1) {
      result.onLine1 = true;
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
      result.onLine2 = true;
    }
    // if line1 and line2 are segments, they intersect if both of the above are true
    return [result.x, result.y];
  }
  private findIntersection(pt: number[][], borders: number[][][]): number[] {
    let minDistance = 99999999999999;
    let target = null;
    for (let j = 0; j < borders.length; j++) {
      let border = borders[j];
      for (let i = 0; i < border.length - 1; i++) {
        let br = [border[i], border[i + 1]];
        let dist = this.distance(pt[0][0], pt[0][1], br[0][0], br[0][1], br[1][0], br[1][1]);

        if (dist < minDistance) {
          let intersection = this.getIntersection(
            pt[0][0],
            pt[0][1],
            pt[1][0],
            pt[1][1],
            br[0][0],
            br[0][1],
            br[1][0],
            br[1][1]
          );
          if (intersection && this.calcIsInsideLineSegment(br[0], br[1], intersection)) {
            minDistance = dist;
            target = intersection;
          }
        }
      }
    }
    return target;
  }

  private slopePts(pt1: number[], pt2: number[]): number {
    return Math.atan2(pt1[1] - pt2[1], pt1[0] - pt2[0]);
  }

  // Gaps should always aling with lines. This function aligns any gaps that deviated from the line
  snapGaps() {
    const featuresCollection = this.draw.getAll();
    const lines = featuresCollection.features.filter((x) => x.properties.type === LineType.line);
    let gaps = featuresCollection.features.filter((x) => x.properties.type === LineType.gap);
    const snappedGaps = gaps.map((gap) => {
      let minDistance = Number.MAX_VALUE;
      let nearestLine = null;
      const gapcoords = gap.geometry.coordinates;
      const middlePoint = [(gapcoords[0][0] + gapcoords[1][0]) / 2, (gapcoords[0][1] + gapcoords[1][1]) / 2];
      lines.forEach((line) => {
        let { minDistance: distance } = this.closestPointOnLine(line.geometry.coordinates, middlePoint);
        if (distance < minDistance) {
          nearestLine = line.geometry.coordinates;
          minDistance = distance;
        }
      });

      let start = this.closestPointOnLine(nearestLine, gapcoords[0]);
      let end = this.closestPointOnLine(nearestLine, gapcoords[gapcoords.length - 1]);
      if (end.segment < start.segment) {
        const temp = start.segment;
        start.segment = end.segment;
        end.segment = temp;
        const tempPt = start.minPoint;
        start.minPoint = end.minPoint;
        end.minPoint = tempPt;
      }
      const snappedGap = [];
      snappedGap.push(start.minPoint);

      // add all points on the line to the gap between start and end
      for (let i = 0; i < end.segment - start.segment; i++) {
        snappedGap.push(nearestLine[start.segment + i]);
      }
      snappedGap.push(end.minPoint);
      gap.geometry.coordinates = snappedGap;
      return gap;
    });

    this.drawLines({
      type: 'FeatureCollection',
      features: [...lines, ...snappedGaps]
    });
    this.saveToHistory();
  }

  // Find the closest point on entire line by iterating over each line segment.
  private closestPointOnLine(
    pts: number[][],
    pt: number[]
  ): { minPoint: number[]; segment: number; minDistance: number } {
    let minDistance = Number.MAX_VALUE;
    let minPoint: number[];
    let segment = -1;
    for (let i = 0; i < pts.length - 1; i++) {
      const ptFound = this.closestPointOnLineSegment(pts[i], pts[i + 1], pt);
      const distance = Math.sqrt(
        (ptFound[0] - pt[0]) * (ptFound[0] - pt[0]) + (ptFound[1] - pt[1]) * (ptFound[1] - pt[1])
      );
      if (distance < minDistance) {
        minDistance = distance;
        minPoint = ptFound;
        segment = i + 1;
      }
    }
    return { minPoint, segment, minDistance };
  }

  //
  //  p1|\
  //	  |	\
  //	 y|	 \
  //	 D|	  \   +(pt)
  //	 e|	   *
  //	 l|		  \
  //	 t|		   \
  //	 a|		    \
  //	  |		     \
  //	  |_________\p2
  //		   xDelta
  // split p1-p2 by the ratio of distance betwee pt-p1 and pt-p2 to get the nearest point
  //
  private closestPointOnLineSegment(p1: number[], p2: number[], pt: number[]): number[] {
    const xDelta = p2[0] - p1[0];
    const yDelta = p2[1] - p1[1];

    let closestPoint = [p1[0], p1[1]];
    if (xDelta === 0 && yDelta === 0) {
      closestPoint = p1;
    } else {
      const u = ((pt[0] - p1[0]) * xDelta + (pt[1] - p1[1]) * yDelta) / (xDelta * xDelta + yDelta * yDelta);

      if (u < 0) {
        closestPoint = p1;
      } else if (u > 1) {
        closestPoint = p2;
      } else {
        closestPoint[0] = p1[0] + u * xDelta;
        closestPoint[1] = p1[1] + u * yDelta;
      }
    }
    return closestPoint;
  }

  async extendLines() {
    const featuresCollection = this.draw.getAll();
    const lines = featuresCollection.features.filter((x) => x.properties.type === LineType.line);
    const gaps = featuresCollection.features.filter((x) => x.properties.type === LineType.gap);
    const parcelShape = this.getParcel();
    if (!parcelShape) {
      message.error('Parcel shape not found.');
      return;
    }
    const intersection = polygonClipping.intersection(
      (parcelShape.features[0].geometry as Geometry).coordinates as any,
      this.analyticRegion.polygon.geometry.coordinates as any
    );
    let joinedLines = { lines: [], gaps: [] };
    intersection.forEach((border) => {
      const joined = this.joinLinesWithBorder(lines, border);
      joinedLines.lines = joinedLines.lines.concat(joined.lines);
      joinedLines.gaps = joinedLines.gaps.concat(joined.gaps);
    });
    const border = intersection[0] as number[][][];
    const mergedGaps = this.concatWithExistingGaps(gaps, joinedLines.gaps);
    this.drawLines({
      type: 'FeatureCollection',
      features: [...joinedLines.lines, ...mergedGaps.filter((x) => x.geometry.coordinates.length > 1)] as any
    });
    this.saveToHistory();
  }

  private concatWithExistingGaps(
    existingGaps: Feature<LineString>[],
    newGaps: Feature<LineString>[]
  ): Feature<LineString>[] {
    for (let i = newGaps.length - 1; i >= 0; i--) {
      let joinedPointsPosition = existingGaps.findIndex(
        (x) =>
          distance(x.geometry.coordinates[0], newGaps[i].geometry.coordinates[0], { units: 'kilometers' }) * 10000000 <
          1
      );
      if (joinedPointsPosition >= 0) {
        existingGaps[joinedPointsPosition].geometry.coordinates = [
          newGaps[i].geometry.coordinates[1],
          ...existingGaps[joinedPointsPosition].geometry.coordinates
        ];
        newGaps.splice(i, 1);
        continue;
      }
      joinedPointsPosition = existingGaps.findIndex(
        (x) =>
          distance(x.geometry.coordinates[0], newGaps[i].geometry.coordinates[1], { units: 'kilometers' }) * 10000000 <
          1
      );
      if (joinedPointsPosition >= 0) {
        existingGaps[joinedPointsPosition].geometry.coordinates = [
          newGaps[i].geometry.coordinates[0],
          ...existingGaps[joinedPointsPosition].geometry.coordinates
        ];
        newGaps.splice(i, 1);
        continue;
      }
      joinedPointsPosition = existingGaps.findIndex(
        (x) =>
          distance(x.geometry.coordinates[x.geometry.coordinates.length - 1], newGaps[i].geometry.coordinates[0], {
            units: 'kilometers'
          }) *
            10000000 <
          1
      );
      if (joinedPointsPosition >= 0) {
        existingGaps[joinedPointsPosition].geometry.coordinates = [
          ...existingGaps[joinedPointsPosition].geometry.coordinates,
          newGaps[i].geometry.coordinates[1]
        ];
        newGaps.splice(i, 1);
        continue;
      }
      joinedPointsPosition = existingGaps.findIndex(
        (x) =>
          distance(x.geometry.coordinates[x.geometry.coordinates.length - 1], newGaps[i].geometry.coordinates[1], {
            units: 'kilometers'
          }) *
            10000000 <
          1
      );
      if (joinedPointsPosition >= 0) {
        existingGaps[joinedPointsPosition].geometry.coordinates = [
          ...existingGaps[joinedPointsPosition].geometry.coordinates,
          newGaps[i].geometry.coordinates[0]
        ];
        newGaps.splice(i, 1);
        continue;
      }
    }
    return [...existingGaps, ...newGaps];
  }

  private removeDuplicates(coords: number[][]): number[][] {
    for (let i = coords.length - 1; i >= 0; i--) {
      if (coords.filter((x) => coords[i][0] === x[0] && coords[i][1] === x[1]).length > 1) {
        coords.splice(i, 1);
      }
    }
    return coords;
  }

  private joinLinesWithBorder(
    lines: Feature<LineString>[],
    border: number[][][]
  ): { lines: Feature<LineString>[]; gaps: Feature<LineString>[] } {
    const newGaps = [];
    const newLines = lines.map((line: Feature<LineString>) => {
      const geom = line.geometry as Geometry;
      const actual: Feature<LineString> = {
        ...line,
        geometry: { ...line.geometry, coordinates: [...line.geometry.coordinates] }
      };
      geom.coordinates = this.removeDuplicates(geom.coordinates as number[][]);
      geom.coordinates = this.removeDuplicates(this.fixZaggedEnds(geom.coordinates as number[][]));
      let first = [geom.coordinates[0], geom.coordinates[1]];
      let last = [geom.coordinates[geom.coordinates.length - 1], geom.coordinates[geom.coordinates.length - 2]];
      let newFirst = this.findIntersection(first as number[][], border);
      let newLast = this.findIntersection(last, border);
      let changed = false;
      let coordinates = [];
      if (!newFirst || !newLast) {
        return actual;
      }
      let distFirst = distance(newFirst, geom.coordinates[0]) * 1000;
      if (
        distFirst < this.extendLineThreshold &&
        distFirst > 0 &&
        (Math.abs(
          this.slopePts(newFirst, geom.coordinates[0] as number[]) -
            this.slopePts(geom.coordinates[0] as number[], geom.coordinates[1] as number[])
        ) *
          180) /
          Math.PI <
          90
      ) {
        changed = true;
        coordinates = [newFirst, ...geom.coordinates];
        newGaps.push({
          type: line.type,
          properties: { type: LineType.gap, color: this.getSegmentColor(LineType.gap) },
          geometry: { type: line.geometry.type, coordinates: [newFirst, geom.coordinates[0]] }
        });
      } else {
        coordinates = [...geom.coordinates];
      }
      let distLast = distance(newLast, geom.coordinates[geom.coordinates.length - 1]) * 1000;
      if (
        distLast < this.extendLineThreshold &&
        distLast > 0 &&
        (Math.abs(
          this.slopePts(newLast, geom.coordinates[geom.coordinates.length - 1] as number[]) -
            this.slopePts(
              geom.coordinates[geom.coordinates.length - 1] as number[],
              geom.coordinates[geom.coordinates.length - 2] as number[]
            )
        ) *
          180) /
          Math.PI <
          90
      ) {
        changed = true;
        coordinates.push(newLast);
        newGaps.push({
          type: line.type,
          properties: { type: LineType.gap, color: this.getSegmentColor(LineType.gap) },
          geometry: { type: line.geometry.type, coordinates: [geom.coordinates[geom.coordinates.length - 1], newLast] }
        });
      }
      if (changed) {
        geom.coordinates = coordinates;
        return line;
      } else {
        return actual;
      }
    });
    return { lines: newLines, gaps: newGaps };
  }
  highlightOverlappingGaps() {
    const featuresCollection = this.draw.getAll();
    const gaps = featuresCollection.features.filter((x) => x.properties.type === LineType.gap);

    let detectedCount = 0;
    gaps.forEach((gap, index) => {
      gap.properties.index = index;
      this.draw.setFeatureProperty(gap.id, 'color', this.getSegmentColor(LineType.gap));
      index++;
    });
    const processed = [];
    gaps.forEach((gap) => {
      for (let i = 0; i < gaps.length; i++) {
        if (gap.properties.index !== gaps[i].properties.index && !processed.find((x) => x === gap.properties.index)) {
          const gapCoords = gap.geometry.coordinates;
          const compareCoords = gaps[i].geometry.coordinates;
          let found = false;
          for (let j = 0; j < gapCoords.length - 1; j++) {
            for (let k = 0; k < compareCoords.length - 1; k++) {
              const overlap = this.checkOverlap(
                [gapCoords[j], gapCoords[j + 1]],
                [compareCoords[k], compareCoords[k + 1]]
              );
              if (overlap) {
                processed.push(gaps[i].properties.index);
                this.draw.setFeatureProperty(gap.id, 'color', this.segmentColorMap['overlap']);
                this.draw.setFeatureProperty(gaps[i].id, 'color', this.segmentColorMap['overlap']);
                detectedCount++;
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        }
      }
    });
    if (detectedCount === 0) {
      message.info('No overlaps detected!');
    } else {
      message.info(detectedCount + ' overlaps detected.');
    }
  }

  //Simple turf.lineOverlap doesn't over for reason, unless one is contained withing the other!!!
  //turf.booleanOverlap also doesn't work. So, check based on slope and distance
  //  +---------+----------+----------------+
  //  A         P          B                Q
  // Lines AB and PQ are said to be overlapping, if Slop(A, B) == Slope(P, Q)
  // distance (A, P) + distance(P, B) = distance(A, B)

  checkOverlap(line1: number[][], line2: number[][]): boolean {
    const slope1 = this.slopePts(line1[0], line1[1]);
    const slope2 = this.slopePts(line2[0], line2[1]);
    if (Math.abs(Math.atan(Math.tan(slope1) - Math.tan(slope2))) * 10000000 > 1) {
      return false;
    }

    const p1 = line2[0];
    const p2 = line2[1];
    const gapLength1 = distance(line1[0], line1[1], { units: 'kilometers' });
    if (
      Math.abs(
        (distance(line1[0], p1, { units: 'kilometers' }) +
          distance(p1, line1[1], { units: 'kilometers' }) -
          gapLength1) *
          10000000
      ) < 1 ||
      Math.abs(
        (distance(line1[0], p2, { units: 'kilometers' }) +
          distance(p2, line1[1], { units: 'kilometers' }) -
          gapLength1) *
          10000000
      ) < 1
    ) {
      return true;
    }
    return false;
  }
}
