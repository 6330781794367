
import { Component, Vue } from 'vue-property-decorator';
import Map from '@/components/Map/Map.vue';
import { MapComponent } from '@/interfaces/mapComponent';
import { AnalyticRegionStateType } from '@/enums/analyticRegionStateType';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import API from '@/services/api';
import { featureCollection } from '@turf/helpers';
import { getIntersectionPolygons } from '@/shared/helpers';
import { lineClipByPolygon } from '@/shared/line-clip-polygon';
import { AnalyticRegionCountByState } from '@/interfaces/analyticRegionCountByState';
import { message } from 'ant-design-vue';
import { AuthorizeResponse } from '@/interfaces/auth';
import { UserInfo } from '@/interfaces/userInfo';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    Map
  }
})
export default class Operator extends Vue {
  $refs: {
    map: MapComponent;
  };
  analyticRegionStateType = AnalyticRegionStateType;
  countAnalyticRegionsCurationRequired = 0;
  analyticRegion: AnalyticRegion = null;
  startCurationTime: Date;
  showParallelLines = false;
  showAllLines = true;
  private refreshSessionInterval = 600000; // millisecond, 10 min

  async mounted(): Promise<void> {
    try {
      const response = await API.authorize('curator');
      const userInfo = response.UserInfo;
      this.$store.dispatch('setUserInfo', userInfo);
      Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }

    setInterval(() => {
      /* eslint-disable-next-line no-console */
      console.info('refreshSession...');
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  get isCurationStarted(): boolean {
    return this.$store.state.underCuration;
  }

  onNewRegionLoaded(analyticRegion: AnalyticRegion): void {
    this.analyticRegion = analyticRegion;

    API.getAnalyticRegionCurationRequiredCount().then((result: AnalyticRegionCountByState) => {
      this.countAnalyticRegionsCurationRequired = result.count;
    });
  }

  save(state: AnalyticRegionStateType, isDraft = false): void {
    const parcel = this.$refs.map.getParcel();
    if (this.analyticRegion && parcel) {
      try {
        this.$refs.map.stopDrawing();
        const lines = this.$refs.map.getAllLines();
        const intersectionPolygons = getIntersectionPolygons(parcel, featureCollection([this.analyticRegion.polygon]));

        let clippedLinesByShapes = [];
        intersectionPolygons.forEach((intersectionPolygon) => {
          const clippedLinesByShape = lineClipByPolygon(lines, intersectionPolygon);
          if (clippedLinesByShape && clippedLinesByShape.length) {
            clippedLinesByShapes = clippedLinesByShapes.concat(clippedLinesByShape);
          }
        });

        this.$store.dispatch('showGlobalLoader', true);
        API.updateRegion(this.analyticRegion.id, {
          state,
          corrected: featureCollection(clippedLinesByShapes),
          curatorEmail: this.userInfo.Email,
          curatorId: this.userInfo.id,
          curatorName: this.userInfo.FirstName,
          curationTime: this.getCurationTime()
        })
          .then(() => {
            if (!isDraft) {
              this.showAllLines = true;
              this.finishCuration();
              this.$refs.map.loadNextRegion();
            }
            message.success('Successfully updated!', 5);
          })
          .finally(() => {
            this.$store.dispatch('showGlobalLoader', false);
          });
      } catch {
        message.error('Unable to clip lines to polygon, please check lines', 5);
      }
    }
  }

  finishCuration(): void {
    this.$store.dispatch('setUnderCuration', false);
    this.startCurationTime = new Date();
  }

  getCurationTime(): number {
    if (!this.isCurationStarted) {
      return 0;
    }
    const endTime = new Date();
    /* eslint-disable-next-line no-console */
    console.info('endTime', endTime);
    const diff = endTime.getTime() - this.startCurationTime.getTime();

    // reset start curation time each time when period is get
    this.startCurationTime = new Date();
    /* eslint-disable-next-line no-console */
    console.info('startCurationTime', this.startCurationTime);

    return diff;
  }

  startCuration(): void {
    this.showAllLines = true;
    this.startCurationTime = new Date();
    this.$store.dispatch('setUnderCuration', true);
    /* eslint-disable-next-line no-console */
    console.info('startCurationTime', this.startCurationTime);
  }

  get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  openLabelling() {
    window.open(process.env.VUE_APP_LABELLING + '?surveyId=' + this.analyticRegion.surveyId, '_blank');
  }
}
