import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { Button, Card, Checkbox, Layout, Spin, Slider } from 'ant-design-vue';

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Spin);
Vue.use(Layout);
Vue.use(Slider);

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api) {
    api.init({ button: null });
    (window as any).Usersnap = api;
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.src = 'https://widget.usersnap.com/load/e1d754ae-d2ae-49f8-8352-a1b2b7310c96?onload=onUsersnapCXLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
