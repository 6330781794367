import { render, staticRenderFns } from "./Curator.vue?vue&type=template&id=230ca7ad&scoped=true&"
import script from "./Curator.vue?vue&type=script&lang=ts&"
export * from "./Curator.vue?vue&type=script&lang=ts&"
import style0 from "./Curator.vue?vue&type=style&index=0&id=230ca7ad&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230ca7ad",
  null
  
)

export default component.exports