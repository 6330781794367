export type StyleRule = string | [];

export function getParallelismStylingRules(): StyleRule[] {
  const types = Object.keys(Constants.parallelismColorMap);
  const stylesRules = [];
  for (let i = 0; i < types.length; i++) {
    const condition = ['==', ['get', 'type'], types[i]];
    stylesRules.push([condition, Constants.parallelismColorMap[types[i]]]);
  }
  return [].concat(...stylesRules);
}

export default class Constants {
  static parallelismColorMap = {
    TOO_WIDE: '#0501ff',
    WIDE: '#adceff',
    TOO_NEAR: '#e31a1c',
    NEAR: '#ff8587'
  };
}
