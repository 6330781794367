import { Feature, FeatureCollection, lineString, LineString, polygon, Polygon } from '@turf/helpers';
import { featureEach } from '@turf/meta';
import { getCoords } from '@turf/invariant';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import lineIntersect from '@turf/line-intersect';
import lineSlice from '@turf/line-slice';
import booleanCrosses from '@turf/boolean-crosses';
import turfIntersect from '@turf/intersect';
import { SplitLinesOfPolygon } from '@/interfaces/split-lines-of-polygon';
import lineOffset from '@turf/line-offset';
import clone from '@turf/clone';

export function getFeaturesUnderPolygon(
  features: FeatureCollection<LineString>,
  polygon: Feature<Polygon>
): Array<Feature<LineString>> {
  const selectedLines = [];
  featureEach(features, (line: Feature<LineString>) => {
    const crosses = booleanCrosses(line, polygon);
    if (crosses) {
      selectedLines.push(line);
      return;
    }
    const lineCoords = getCoords(line);
    const firstIncluded = booleanPointInPolygon(lineCoords[0], polygon);
    if (firstIncluded) {
      selectedLines.push(line);
      return;
    }
    const lastIncluded = booleanPointInPolygon(lineCoords[lineCoords.length - 1], polygon);
    if (lastIncluded) {
      selectedLines.push(line);
      return;
    }
  });
  return selectedLines;
}

// tslint:disable-next-line:cognitive-complexity
export function splitLinesToInSideAndOutSideOfPolygon(
  lines: Array<Feature<LineString>>,
  polygon: Feature<Polygon>
): SplitLinesOfPolygon {
  const result = {
    outside: [],
    inside: []
  };
  lines.forEach((line: Feature<LineString>) => {
    const intersect = lineIntersect(line, polygon);
    const lineCoords = getCoords(line);
    if (intersect.features.length === 0) {
      const clonedLine = clone(line);
      delete clonedLine.id;
      result.inside.push(clonedLine);
    }
    if (intersect.features.length === 1) {
      const firstIncluded = booleanPointInPolygon(lineCoords[0], polygon);
      const line1 = lineSlice(lineCoords[0], intersect.features[0].geometry.coordinates, line);
      const line2 = lineSlice(intersect.features[0].geometry.coordinates, lineCoords[lineCoords.length - 1], line);
      result.inside.push(firstIncluded ? line1 : line2);
      result.outside.push(firstIncluded ? line2 : line1);
    }
    if (intersect.features.length === 2) {
      let leftLine = lineCoords[0];
      let rightLine = lineCoords[lineCoords.length - 1];
      if (Math.abs(leftLine[0]) - Math.abs(rightLine[0]) < 0) {
        leftLine = lineCoords[lineCoords.length - 1];
        rightLine = lineCoords[0];
      }

      let leftIntersect = intersect.features[0].geometry.coordinates;
      let rightIntersect = intersect.features[1].geometry.coordinates;
      if (Math.abs(leftIntersect[0]) - Math.abs(rightIntersect[0]) < 0) {
        leftIntersect = intersect.features[1].geometry.coordinates;
        rightIntersect = intersect.features[0].geometry.coordinates;
      }

      result.outside.push(lineSlice(leftLine, leftIntersect, line));
      result.inside.push(
        lineSlice(intersect.features[0].geometry.coordinates, intersect.features[1].geometry.coordinates, line)
      );
      result.outside.push(lineSlice(rightIntersect, rightLine, line));
    }
  });
  return result;
}

export function getIntersectionPolygons(
  parcelShape: FeatureCollection,
  regionShape: FeatureCollection
): Array<Feature<Polygon>> {
  const {
    features: [parcelShapeFeature]
  } = parcelShape;
  const {
    features: [regionShapeFeature]
  } = regionShape;
  const intersectionPolygon = turfIntersect(
    parcelShapeFeature as Feature<Polygon>,
    regionShapeFeature as Feature<Polygon>
  );
  if (intersectionPolygon.geometry.type === 'Polygon') {
    return [intersectionPolygon as Feature<Polygon>];
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (intersectionPolygon as Feature<Polygon>).geometry.coordinates.map((coords) => polygon(coords as any));
}

export function getInsidePolygonByOffset(basePolygon: Feature<Polygon>, distance: number): Feature<Polygon> {
  const coordinates = basePolygon.geometry.coordinates;
  const bottomHorzLine = lineOffset(lineString([coordinates[0][0], coordinates[0][1]]), -distance, { units: 'meters' });
  const topHorzLine = lineOffset(lineString([coordinates[0][3], coordinates[0][2]]), distance, {
    units: 'meters'
  });
  const leftVertLine = lineOffset(lineString([coordinates[0][3], coordinates[0][0]]), -distance, {
    units: 'meters'
  });
  const rightVertLine = lineOffset(lineString([coordinates[0][2], coordinates[0][1]]), distance, {
    units: 'meters'
  });

  const leftTop = lineIntersect(leftVertLine, topHorzLine).features[0].geometry.coordinates;
  const leftBottom = lineIntersect(leftVertLine, bottomHorzLine).features[0].geometry.coordinates;
  const rightBottom = lineIntersect(bottomHorzLine, rightVertLine).features[0].geometry.coordinates;
  const rightTop = lineIntersect(rightVertLine, topHorzLine).features[0].geometry.coordinates;
  return polygon([[leftTop, leftBottom, rightBottom, rightTop, leftTop]]);
}
