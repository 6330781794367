
import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {
  private userSnapCommentTemplate =
    'Title: \n\n' +
    '🔴 Actual result (what you see):\n\n' +
    '🟢 Desired result (what you expect to see):\n\n' +
    '🔃 Steps to reproduce (what you did to get the actual result):\n';

  openFeedback(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userSnap = (window as any).userSnap;
    if (userSnap) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userSnap.on('open', (event: any) => {
        event.api.setValue('visitor', this.$store.state.userInfo ? this.$store.state.userInfo.Email : null);
        event.api.setValue('comment', this.userSnapCommentTemplate);
        event.api.setValue('custom', this.$store.state.userInfo);
      });
      userSnap.open();
    }
  }
}
